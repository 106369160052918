import { Injectable } from '@angular/core'
import { ConfigService } from '../config/ungs_activa_mobile_config'
import { HttpClient } from '@angular/common/http'
import { HerramientasService } from '../herramientas/herramientas.service'

@Injectable({
  providedIn: 'root',
})
export class MapucheService {
  config = ConfigService

  constructor(private http: HttpClient, public herramientas: HerramientasService) {}

  obtenerCuitAgente(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/mapuche/agente/cuit')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }

  obtenerLegajoAgente(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/mapuche/personas/legajo')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }

  obtenerCargosVigentes(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/mapuche/personas/cargos_vigentes')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }

  usuarioAutorizado(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/mapuche/fondo_solidario/usuario_autorizado')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }

  obtenerRecibosUsuario(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/mapuche/recibos')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }

  obtenerSuscripcionesFondoSolidario(legajo?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/mapuche/fondo_solidario/obtener_suscripciones/', { legajo: legajo })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }

  registrarSuscripcionFondoSolidario(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/mapuche/fondo_solidario/registrar_suscripcion', {})
        .toPromise()
        .then((response: string) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }

  obtenerFormulariosGananciasUsuario(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/mapuche/formularios')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }

  descargarRecibos(recibos: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/mapuche/recibos/descarga', recibos)
        .toPromise()
        .then((response: any) => {
          resolve(response)
        })
        .catch((error: any) => reject(error))
    })
  }

  obtenerAreas(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/mapuche/areas')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }
}
